<template>  
  <ion-item @click="view(guideline.id)" lines="none" color="light" class="my-2" button detail="false"
   v-for="guideline in guidelines" :key="guideline.id" style="overflow: show;">

    <ion-label>
      <div class="container p-md-2 px-md-4">
        <div class="row align-items-center">
          <div class="col-10 col-md-4 col-lg-3 order-1">
            <h3>{{ guideline.title }}</h3> 
          </div>
          <div class="col-md-4 col-lg-3 order-3">
            <p>{{ guideline.issuer }}</p>
          </div>
          <div class="col-md-3 col-lg-3 order-4">
            <p>{{ he.GuidelinesList.text }} {{ guideline.updatedAt }}</p>
          </div>                                
          <div class="col-2 col-lg-3 order-2 order-md-last ion-text-left">
            <bookmark :guidelineId="guideline.id"/>
            <button class="actionBtn me-5 d-none d-lg-inline">{{ he.GuidelinesList.button }} ></button>                    
          </div>                                             
        </div>              
      </div>   
    </ion-label>
  </ion-item>    
</template>

<style scoped>

  p {
    font-weight: 600;
  }

@media only screen and (max-width: 768px) {

  h3 {
    font-size: 20px !important;
  }
  
  p {
    color: #474747;
  }  
}  
</style>

<script>
import he from '../json/he.json';
import bookmark from './bookmarkGuideline.vue';
import { IonItem, IonLabel } from '@ionic/vue';
import { mapState, mapActions } from 'vuex';

export default {

  components: {
    IonItem, IonLabel, bookmark
    }, 
    
  data() {
    return {
      he
    }
  },    

  computed: {
    ...mapState(["guidelines"]),
  },

  methods: {
    ...mapActions(["getGuideline", "getBookmarks", "setKeyword"]),   

    view(id) {
      this.setKeyword("");
      this.getGuideline(id);
      this.$router.push(`/guidelines/${id}`);     
    }

  },

  mounted() {
        this.getBookmarks();  
  }
}
</script>