<template>
    <div v-if="isAuthenticated" class="d-flex align-items-center">
        <img :src="user.picture" class="rounded-circle me-4 ms-3">
        <li class="nav-item dropdown">
            <div class="d-flex align-items-center" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <span class="nav-link text-light p-0">{{ he.userPanel.greeting }} {{ user.name }}</span>
                <ion-icon class="text-light mx-3" :icon="chevronDownOutline"></ion-icon>
            </div>
          <ul class="dropdown-menu ion-text-right">
            <li><a class="dropdown-item" @click="logout"><ion-icon color="secondary" class="ms-3" :icon="logOutOutline"></ion-icon>{{ he.userPanel.button }}</a></li>
          </ul>
        </li>
        <span class="d-flex text-light" style="font-size: 50px; font-weight: 100">|</span>
        <ion-icon @click="$router.push(`/home`); if (keyword) setKeyword('');" class="me-3 bookmarkBtn d-flex text-light" :icon="bookmarkOutline"></ion-icon>
    </div>    
</template>

<script>
import he from '../json/he.json';
import { IonIcon } from '@ionic/vue';
import { bookmarkOutline, chevronDownOutline, logOutOutline } from 'ionicons/icons';
import { mapState, mapActions } from 'vuex';
import { useAuth0 } from '@auth0/auth0-vue';

export default {

    components: {
        IonIcon
    },
    
    setup() {
        const { logout, isAuthenticated, user } = useAuth0();

        return {
            logout: () => {
                logout({ returnTo: window.location.origin });
            },
            user,
            isAuthenticated,            
            bookmarkOutline,
            chevronDownOutline,
            logOutOutline
        }
    },

    data() {
        return {
            he
        }
    },    

    computed: {
        ...mapState(["keyword"])
    },
    
    methods: {
        ...mapActions(["setKeyword"])
    },  

    mounted() {
        if (!this.isAuthenticated)
            this.$router.push('/login')
    }
}
</script>

<style scoped>
    li {
        list-style: none;
    }

    .dropdown-item:hover,
    .dropdown-item:active {
        cursor: pointer;
        color: black;
        background: #f1f1f1;
    }

</style>
