<template>
  <ion-app>

    <!-- Tabs -->
    <ion-tabs>
      <ion-router-outlet id="main"/>
      <ion-tab-bar slot="bottom" class="d-flex d-md-none">

        <ion-tab-button tab="home" href="/home" @click="if (keyword) setKeyword('');">
          <ion-icon :icon="bookmarkOutline" size="large"></ion-icon>
        </ion-tab-button>

        <ion-tab-button tab="profile" @click="openMenu">
          <ion-icon :icon="menuOutline" size="large"></ion-icon>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>

    <!-- Side menu -->

    <ion-menu side="start" class="d-md-none sideMenu"
    menu-id="patientCharacteristics" content-id="main">
      <div class="container p-3 text-end">
          <h4 class="py-3 border-bottom">מאפייני מטופל</h4> 
          <patientCharacteristics/>         
      </div>
      <div class="row align-items-baseline footer py-3">
        <div class="col-5 text-center">
          <span class="link">נקה הכל</span>                             
        </div>
        <div class="col">
          <button class="btn btn-primary">אישור</button>     
        </div>
      </div>         
    </ion-menu>
  </ion-app>
</template>

<script>
import patientCharacteristics from './components/PatientCharacteristics.vue';
import { IonApp, IonRouterOutlet, IonIcon, IonTabButton, IonTabBar, IonTabs, actionSheetController, IonMenu} from '@ionic/vue';
import { menuOutline, bookmarkOutline, logOutOutline, chevronBackOutline, funnelOutline } from 'ionicons/icons';
import { useAuth0 } from '@auth0/auth0-vue';
import { mapState, mapGetters, mapActions } from 'vuex';

export default { 
  name: 'App',
  components: {
    IonApp, IonRouterOutlet,IonIcon,
    IonTabButton, IonTabBar, IonTabs,
    IonMenu,
    patientCharacteristics
  },

  setup() {
    const { logout, user } = useAuth0();
    
    return {
      logout: () => {
          logout({ returnTo: window.location.origin });
      },   
      user,
      logOutOutline,
      menuOutline,
      bookmarkOutline,
      chevronBackOutline,
      funnelOutline
    }    
  },

  computed: {
      ...mapState(["keyword"]),
      ...mapGetters(["characteristics"])
  },  

  methods: {
    ...mapActions(["setKeyword"]),

    async openMenu() {
        await this.user;
        const actionSheet = await actionSheetController
        .create({
            header: this.user.name,
            buttons: [              
            {
                text: 'התנתק',
                icon: logOutOutline,
                handler: () => {
                this.logout();
                }
            }
            ],
        });
        await actionSheet.present();        
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@300;400;600;700&display=swap');

* {
  font-family: 'Assistant', sans-serif;
  font-size: 18px !important;
  color: #1F2124;
}

h1 {
  font-size: 34px !important;
  font-weight: 600;
}

h1,
h2,
h4 {
  font-weight: bold !important
}

h2 {
  font-size: 28px !important;
}

h3 {
  font-size: 18px !important;
}

h3,
h5 {
  font-weight: 600 !important
}

h4 {
  font-size: 20px !important;
}

h5 {
  font-size: 17px !important;
}

.link {
  cursor: pointer;
  color: #4A3FD7 !important;
  text-decoration: underline;
}

.btn-primary {
  width: 150px;
  height: 50px;
  color: var(--ion-color-light);
  background: var(--ion-color-secondary) !important;
}

.logo {
  height: 61px;
  width: 264px;
  cursor: pointer;
}

img.rounded-circle {
  width: 59px;
  height: 59px;
  background-color: lightgray;
}

.box {
  height: 50px;
  line-height: 50px;
  vertical-align: middle;
}

.searchbar-input {
  height: 50px !important;
  vertical-align: middle !important;
  font-size: 18px !important;
  border-radius: 5px !important;
}

.searchbar-search-icon.sc-ion-searchbar-md {
  top: 15px;
}

ion-header .button-solid {
   height: 50px !important;
}

.ion-text-wrap div.row .detailsBox {
  background-color: #F0F3F3;
  border-radius: 5px;
  padding: 8px;
  height: 100%;
}

.ion-text-wrap div.row div.col,
.ion-text-wrap div.row div.col-md-5,
.ion-text-wrap div.row div.col-md-11  {
  margin-bottom: 10px;
}

.bookmarkBtn {
  cursor: pointer;
  width: 25px;
  height: 25px;
}

.actionBtn {
  color: var(--ion-color-secondary);
  background: transparent;
  border: 1px solid var(--ion-color-secondary);
  border-radius: 5px;
  min-width: 100px;
  padding: 10px;
}

.container.sticky-md-top {
  background: var(--ion-background-color);
}

.sideBar {
  box-shadow: 0px 3px 6px #00000029;
  background: var(--ion-color-light); 
  height: 89.5vh;
  width: 36%;
  max-width: 501px;
  overflow: hidden;
}

.sideBar .header {
  background: #F0F3F3;
  padding: 24px 52px;
}

.sideBar .header.clickable {
  cursor: pointer;
  padding-left: 16px;
}

.sideBar .header h4 {
  color: var(--ion-color-secondary);
  font-weight: bold;
  margin-right: 15px;
}

.sideBar .item {
  border-bottom: 1px solid var(--ion-color-light-shade);
}

.sideBar .item,
.sideBar .last-item {
  margin-right: 37px;
}

ion-menu .item,
.sideBar .item,
.sideBar .last-item {
  padding: 16px;
}

.sideBar .list {
  height: 40vh;
  overflow-y: scroll;
}

.list .item button {
  background: transparent;
  border: 1px solid var(--ion-color-medium);
  border-radius: 5px;
  min-width: 72px;
  height: 45px;
  padding: 10px 15px;
}    

.list .item span {
  font-weight: 600;
  margin: 0;
}

.sideBar ion-icon.big {
  font-size: 28px !important;
}

ion-menu {
  --background: var(--ion-color-light);
}

.sideMenu .footer {
  box-shadow: 0px 0px 6px #00000029;  
}

@media only screen and (max-width: 768px) {

  ion-toolbar {
    min-height: initial;
  }  

  .logo {
    height: initial;
    width: 200px;
  }

  img.rounded-circle {
    width: 40px;
    height: 40px;
  }


  h1, .sc-ion-label-md-s h1 {
    font-size: 24px !important;
  }
  
  h2, .sc-ion-label-md-s h2 {
    font-size: 17px !important;
  }

  h3, .sc-ion-label-md-s h3 {
    font-size: 18px !important;
    font-weight: 600 !important;
  }  

  ion-searchbar {
    padding: 0 !important;
  }

  .searchbar-input {
    height: initial !important;
    min-height: 42px;
    border-radius: 5px !important;
  }

  .searchbar-search-icon.sc-ion-searchbar-md {
    top: 10px;
  }

  ion-header .button-solid {
   height: 42px !important;
}  

.bookmarkBtn {
  width: 20px;
  height: 20px;
}  

.ion-text-wrap div.row div.col,
.ion-text-wrap div.row div.col-12 {
  margin-bottom: 5px;
}

.container {
  padding: 0 12px;
}

}

</style> 