<template>
    <ion-page>
        <div class="mx-4 my-2"><h1>{{ message }}</h1></div>
    </ion-page>
</template>
<script>
    import he from '../json/he.json';
    import { IonPage} from '@ionic/vue';
    import { useAuth0 } from '@auth0/auth0-vue';
    import { mapActions } from 'vuex';

  export default {

  components: {
      IonPage
  },
          
  setup() {
    const { user, loginWithRedirect, idTokenClaims } = useAuth0();

    return {
      login: () => {
        loginWithRedirect();
      },
      user,
      idTokenClaims
    };
  },

  data() {
    return {
      message: "",
      he
    }
  },

  methods: {
    ...mapActions(["setToken", "setUID"])
  },      

    beforeMount() {
        if (this.$route.query.code == undefined)
            this.login();
        else
          this.message = he.MemberLogin.message;
        
    },

    updated() {
      if (this.idTokenClaims != undefined) {    
        this.setToken(this.idTokenClaims.__raw)   
        this.setUID(this.user.sub.substring(6)) 
        
        if (this.$route.path == "/login") {
          this.$router.push("/home");  
          this.message = "";
        }      
      } 
    }
  };
</script>