<template>
  <ion-page >
    <Header/>

    <ion-content>
      <div class="container ion-text-center mt-5">
        <GuidelinesBox />
      </div>
    </ion-content>    
  </ion-page>     
</template>


<script>
import Header from '../components/headers/homeHeader.vue'
import GuidelinesBox from '../components/GuidelinesBox.vue';
import { IonPage, IonContent } from '@ionic/vue';
import { mapState } from 'vuex';

export default {

  components: {
    IonPage, IonContent,
    Header, GuidelinesBox
  },

  computed: {
    ...mapState(["keyword", "guidelines"])
  }
};
</script>
