import { createApp } from 'vue'
import { createStore } from 'vuex'
import App from './App.vue'
import router from './router';
import { IonicVue } from '@ionic/vue';
import { DateTime } from "luxon";
import { Storage } from '@ionic/storage';
import { createAuth0 } from '@auth0/auth0-vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import BaseLayout from './components/layouts/baseLayout.vue';

/* Ionic storage */
const localStorage = new Storage();
localStorage.create();

const axios = require('axios');
const store = createStore({

  state () { //Data
      return {
          token: "",
          uid: "",
          patientCharacteristics: [],
          keyword: "",
          query: "",
          bookmarks: "",
          guidelines: [],
          guideline: {}
      }
  },
  getters: { //Computed
    characteristics(state) {
      const characteristics = [];
      const ids = [];

      // Tests
      const filteredTests = state.guideline.tests != undefined ? state.guideline.tests.filter(test => test.patientCharacteristics.length > 0) : [];
      for(let i=0;i<filteredTests.length;i++) {
          for(let ii=0;ii<filteredTests[i].patientCharacteristics.length;ii++) {
              if (!ids.includes(filteredTests[i].patientCharacteristics[ii].id)) {
                  ids.push(filteredTests[i].patientCharacteristics[ii].id);
                  characteristics.push(filteredTests[i].patientCharacteristics[ii]);
              }
          }
      }

      //Treatments
      const filteredTreatments = state.guideline.treatments != undefined ? state.guideline.treatments.filter(treatment => treatment.patientCharacteristics.length > 0) : [];      
      for(let i=0;i<filteredTreatments.length;i++) {
          for(let ii=0;ii<filteredTreatments[i].patientCharacteristics.length;ii++) {
              if (!ids.includes(filteredTreatments[i].patientCharacteristics[ii].id)) {
                  ids.push(filteredTreatments[i].patientCharacteristics[ii].id);
                  characteristics.push(filteredTreatments[i].patientCharacteristics[ii].title);
              }
          }
      }
      
      return characteristics;
    },

    characteristicIndex: (state) => (obj) => {
      for (let i=0;i<state.patientCharacteristics.length;i++) {
          if (state.patientCharacteristics[i].id == obj.id) {
            return i;
          }
      }
      return -1;  
    }

  },    
  actions: { // Methods   
    
    setKeyword({ commit }, keyword) {
      commit('setKeyword', keyword);
    },    

    getGuidelines({ commit }, keyword) {
      commit('getGuidelines', keyword);
    },   

    getGuideline({ commit }, obj) {
      commit('getGuideline', obj);
    },

    createConfig({ commit }) {
      commit('createConfig');
    },

    getBookmarks({ commit }) {
      commit('getBookmarks');
    },    

    setBookmarks({ commit }, value) {
      commit('setBookmarks', value);  
    },

    setToken({ commit }, value) {
      commit('setToken', value);
    },
    
    setUID({ commit }, value) {
      commit('setUID', value);
    },     
    
    addPatientCharacteristic({ commit }, obj) {
      commit('addPatientCharacteristic', obj);
    },

    updatePatientCharacteristic({ commit }, obj) {
      commit('updatePatientCharacteristic', obj);
    }    


  },

  mutations: {


    setKeyword(state, keyword) {
      state.keyword = keyword;
    },
   
    async getGuidelines (state) {

        let route = "guidelines?ids=" + state.bookmarks;
        if (state.keyword && state.keyword != "") {
          route = "guidelines?k=" + state.keyword;
          state.query = state.keyword
        } else 
          state.query = "";


        axios.get(process.env.VUE_APP_FRONTEND_API + route, {
          headers: {
            'Authorization': 'Bearer ' + state.token
          }
        })
        .then((res) => {
          state.guidelines = res.data;

          for(let i=0;i<state.guidelines.length;i++)
            state.guidelines[i].updatedAt = DateTime.fromISO(state.guidelines[i].updatedAt).setLocale('he').toFormat('MMMM yyyy');
            
        })
        .catch(() => {
          router.push("/login");
        });   
    },

    async getGuideline (state, id) {



      /** Check if any characteristics were selected */ 
      const characteristics = [];
      if (state.patientCharacteristics != undefined) {
        for(let i=0;i<state.patientCharacteristics.length;i++) {
          if (!characteristics.includes(state.patientCharacteristics[i].id) && state.patientCharacteristics[i].status == "true")
            characteristics.push(state.patientCharacteristics[i].id);
        }
      }

      axios.get(`${process.env.VUE_APP_FRONTEND_API}guidelines/${id}?characteristics=${characteristics.join(",")}`, {
        headers: {
          'Authorization': 'Bearer ' + state.token
        }
      })
      .then((res) => {
        state.guideline = res.data;
        state.guideline.updatedAt = DateTime.fromISO(state.guideline.updatedAt).setLocale('he').toFormat('MMMM yyyy');

      })
      .catch(() => {
        router.push("/login");
      });  
    },

    createConfig(state) {
      axios.post(`${process.env.VUE_APP_FRONTEND_API}users`, { id: state.uid }, {
        headers: {
          'Authorization': 'Bearer ' + state.token
        }
      })
      .catch(() => {
        router.push("/login");
      });        
    },    

    getBookmarks(state) {
        axios.get(`${process.env.VUE_APP_FRONTEND_API}users/${state.uid}`, {
          headers: {
            'Authorization': 'Bearer ' + state.token
          }
        })
        .then((res) => {
          if (!res.data.message) { // user exists ?
              if (res.data.bookmarks) {
                state.bookmarks = res.data.bookmarks;
                this.dispatch('getGuidelines');
              }
          } else // create user
            this.dispatch('createConfig')
        })
        .catch(() => {
          router.push("/login");
        });        
      },

    setBookmarks(state, value) {
      axios.put(`${process.env.VUE_APP_FRONTEND_API}users/${state.uid}`, { bookmarks: value }, {
        headers: {
          'Authorization': 'Bearer ' + state.token
        }
      })
      .then((res) => {
        state.bookmarks = res.data.bookmarks;
        this.dispatch('getGuidelines');
      })
      .catch(() => {
        router.push("/login");
      });        
    },

    setToken(state, value) {
      state.token = value;     
    },

    setUID(state, value) {
      state.uid = value;     
    },    

    addPatientCharacteristic(state, obj) {
      state.patientCharacteristics.push(obj)
    },

    updatePatientCharacteristic(state, obj) {
      state.patientCharacteristics[obj.id].status = obj.value;          
    }    

  }
})

const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(store)
  .use(
    createAuth0(
      {
        domain: process.env.VUE_APP_AUTH0DOMAIN,
        client_id: process.env.VUE_APP_AUTH0CLIENTID,
        redirect_uri: window.location.origin,
      }
    )
  );

app.component('base-layout', BaseLayout)
  
router.isReady().then(() => {
  app.mount('#app');
});