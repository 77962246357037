<template>
  <div class="d-flex align-items-center">
    <ion-searchbar class="d-inline-block" color="light" enterkeyhint="search" @ionInput="setKeyword($event.target.value);" :placeholder="he.searchBar.placeholder" :value="keyword"></ion-searchbar><ion-button color="light" @click="getGuidelines()">{{ he.searchBar.button }}</ion-button>                     
  </div>
</template>

<script>
import he from '../json/he.json';
import { IonSearchbar, IonButton } from '@ionic/vue';
import { mapActions, mapState } from 'vuex';

export default {

  components: {
    IonSearchbar, IonButton
    },

  data() {
    return {
      he
    }
  },
      
    
  computed: {
    ...mapState(["keyword"])
  },

  methods: {
    ...mapActions(["setKeyword", "getGuidelines"])
  },

  updated() {
      if (this.keyword == '' || this.keyword == undefined)
        this.getGuidelines();
  }

}
</script>
