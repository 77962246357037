<template>
    <div>
            <!-- Results title -->
            <ion-item v-show="query" lines="none" class="my-4">
            <ion-label>
                <h1>{{ he.GuidelinesBox.title }} "{{ query }}"</h1>
                <h2 v-if="guidelines.length > 0">{{ he.GuidelinesBox.subtitle1 }}</h2>
                <h2 v-else>{{ he.GuidelinesBox.subtitle2 }}</h2>
            </ion-label>
            </ion-item>               
            
        <GuidelinesList/>
    </div>
</template>

<script>
import he from '../json/he.json';
import GuidelinesList from './GuidelinesList.vue';
import { IonItem, IonLabel } from '@ionic/vue';
import { mapState } from 'vuex';

export default {
    
    components: {
        GuidelinesList,
        IonItem, IonLabel
    },

    data() {
        return {
            he
        }
    },    

    computed: {
        ...mapState(["guidelines", "query"])
    }    
}
</script>
