<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button :default-href="backLink"></ion-back-button>
        </ion-buttons>         
        <ion-title>{{ pageTitle }}</ion-title>
        <ion-title size="small">{{ pageSubtitle }}</ion-title>    
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
        <slot />
    </ion-content>
  </ion-page>    
</template>

<script>
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonBackButton,
    IonButtons

} from "@ionic/vue"
export default {
    props: ['pageTitle', 'pageSubtitle', 'backLink'],
    components: {
        IonPage,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonBackButton,
        IonButtons
    }
}
</script>

<style>

</style>
