<template>
    <ion-icon color="tertiary" v-if="isBookmarked(guidelineId)" @click.stop="bookmarkGuideline(guidelineId)" :icon="bookmark" class="bookmarkBtn"></ion-icon>
    <ion-icon v-else color="tertiary" @click.stop="bookmarkGuideline(guidelineId)" :icon="bookmarkOutline" class="bookmarkBtn"></ion-icon>
</template>

<script>
import { IonIcon } from '@ionic/vue';
import { bookmark, bookmarkOutline } from 'ionicons/icons';
import { mapState, mapActions } from 'vuex';

export default {
    
    props: {
        guidelineId: Number
    },

    components: {
        IonIcon
    },    

    setup() {
        return {
            bookmark,
            bookmarkOutline    
        }      
    },

    computed: {
    ...mapState(["bookmarks"]),
  },    

    methods: {
        ...mapActions(["setBookmarks"]),

        isBookmarked(id) {
        return this.bookmarks.includes(id.toString());
        },

        bookmarkGuideline(id) {

        let bookmarks = this.bookmarks != "" ? this.bookmarks.split(",") : [];
        if (bookmarks.includes(id.toString())) 
            bookmarks = bookmarks.filter(bookmark => bookmark != id.toString());
        else
            bookmarks.push(id.toString());   

        this.setBookmarks(bookmarks.join()); 

        }    

    }    
}
</script>