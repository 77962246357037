<template>
    <div class="list">
        <div class="item" @click="toggleCharacteristic(characteristic.id)"
            v-for="characteristic in characteristics" :key="characteristic.id">
            <div class="row align-items-baseline">
                <div class="col-10">
                    <span>{{ characteristic.title }}</span>                  
                </div>
                <div class="col-1">
                    <ion-icon v-if="!characteristicOpened(characteristic.id)" class="ion-text-end" :icon="chevronDownOutline"></ion-icon>
                    <ion-icon v-else class="ion-text-end" :icon="chevronUpOutline"></ion-icon>
                </div>
                <div class="col" v-show="characteristicOpened(characteristic.id)">
                    <div v-show="specialCases[characteristic.snomedId]">
                        <button class="mx-1 my-1" 
                            v-for="option in specialCases[characteristic.snomedId].options"  @click.stop="" :key="option">{{ option }}
                        </button>
                    </div>
                </div>
            </div>                
        </div>
    </div>
</template>

<script>
import he from '../json/he.json';
import { IonIcon } from '@ionic/vue';
import { chevronUpOutline, chevronDownOutline } from 'ionicons/icons';
import { mapState, mapActions, mapGetters } from 'vuex';

export default {

    components: {
       IonIcon
    },

    setup() {
        return {
            chevronUpOutline,
            chevronDownOutline
        }
    },

    data() {
        return {
            he,
            items: []
        }
    },

    computed: {
        ...mapState(["patientCharacteristics"]),
        ...mapGetters(["characteristics", "characteristicIndex"]),

        specialCases() {
            return {   
                // snomedId for characteristic
                133936004: {
                    options: JSON.parse(this.he.patientCharacteristics["133936004"])
                },
                263495000: {
                    options: JSON.parse(this.he.patientCharacteristics["263495000"])
                }
            }
        }
    },    

    methods: {
        ...mapActions(["addPatientCharacteristic", "updatePatientCharacteristic"]),

        setPatientCharacteristics(obj, status) {

            if (this.characteristicIndex(obj) == -1) {
                obj.status = status;
                this.addPatientCharacteristic(obj);
            } else
                this.updatePatientCharacteristic({ id: this.characteristicIndex(obj), value: status });     
        },

        characteristicOpened(id) {
        return this.items.includes(id);
        },

        toggleCharacteristic(id) {
        if (this.characteristicOpened(id))
            this.items = this.items.filter(item => item != id);
        else
            this.items.push(id);
        }     
    }    
}
</script>