<template>
    <ion-header>
        <ion-toolbar color="primary">    
            <div class="container-fluid ps-md-4 pt-3 pb-5">
                <div class="row px-md-5">
                    <div class="col-8 col-xl-10 d-none d-md-block">
                        <user-panel/>
                    </div>
                    <div class="col col-xl-2 col-lg-4 col-md-4 text-center align-self-end">
                        <my-logo/>
                    </div>
                </div>
                <div class="row justify-content-center pt-3 pt-md-5">
                    <div class="col-12 col-xl-6 col-md-9">
                    <h1 class="text-center mb-3 text-light">{{ he.homeHeader.title }}</h1>
                        <search-bar/>
                    </div>
                </div>
            </div>               
        </ion-toolbar>          
    </ion-header>   
</template>

<script>
import he from '../../json/he.json';
import myLogo from '../myLogo.vue';
import userPanel from '../userPanel.vue';
import searchBar from '../searchBar.vue';
import { IonHeader, IonToolbar } from '@ionic/vue';

export default {
    components: {
        IonHeader, IonToolbar,
        myLogo, userPanel, searchBar
    },

    data() {
        return {
            he
        }
    }    
}
</script>
