import { createRouter, createWebHistory } from '@ionic/vue-router';
import Home from '../views/HomePage.vue';
import Guideline from '../views/GuidelineView.vue';
import Member from '../views/MemberLogin.vue';


const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/home',
    component: Home
  },
  {
    path: '/guidelines/:id',
    component: Guideline
  },
  {
    path: '/login',
    component: Member
  } 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
