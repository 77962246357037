<template>
  <ion-page>    
    <Header/>    
    <ion-content>

      <div class="d-flex flex-row" >
        <!-- Side bar - Desktop -->
        <div v-if="!query.length" class="sideBar d-none d-md-block">
          <div class="d-flex flex-column">
            <!-- Guideline -->
            <div class="header">
              <div class="d-flex align-items-center">
                <ion-icon class="big" color="secondary" :icon="documentOutline"></ion-icon>
                <h4 class="d-inline-block">{{ he.GuidelineView.sideBar.title }}</h4>  
              </div>                      
            </div>
            <div class="item">
              <!-- Title -->          
              <h1>{{ guideline.title }}</h1>
            </div>
            <div class="item">
              <!-- Created by -->
              <span class="title">{{ he.GuidelineView.sideBar.item1 }}: </span>{{ guideline.issuer }}
            </div>
            <div :class="guideline.definition ? 'item' : 'last-item'">
              <!-- Last updated -->
              <span class="title">{{ he.GuidelineView.sideBar.item2 }}: </span>{{ guideline.updatedAt }}
            </div>  
            <div v-if="guideline.definition" :class="findings.length ? 'item' : 'last-item'">
              <!-- Definition -->
              <span class="title">{{ he.GuidelineView.sideBar.item3 }}</span><p>{{ definition }}</p>
            </div>
            <div v-if="findings.length" class="last-item">
              <!-- Findings -->
              <span class="title">{{ he.GuidelineView.sideBar.item4 }}</span><p>{{ findings }}</p>
            </div>
            <!-- Characteristics -->
            <div v-show="characteristics.length" class="header clickable" @click="sideBar.characteristics = !sideBar.characteristics">
                <div class="row align-items-baseline">
                    <div class="col-10">
                      <div class="d-flex align-items-center">
                        <ion-icon class="big" color="secondary" :icon="optionsOutline"></ion-icon>
                        <h4 class="d-inline-block">{{ he.GuidelineView.sideBar.title2 }}</h4> 
                      </div>                 
                    </div>
                    <div class="col">
                    <ion-icon v-if="sideBar.characteristics" class="ion-text-end" color="secondary" :icon="chevronUpOutline"></ion-icon>
                    <ion-icon v-else class="ion-text-end" color="secondary" :icon="chevronDownOutline"></ion-icon>
                    </div>
                </div>              
            </div> 
            <patientCharacteristics v-show="sideBar.characteristics"/>              
          </div>           
        </div> 
        <div class="w-100 mx-md-1" style="height: 89.5vh; overflow-y: scroll; overflow-x: hidden;">
          <div v-if="!query.length">
            <div class="container sticky-md-top me-md-0 pt-md-4 pb-1 pb-md-4">  
             
              <!-- Tab selection buttons  -->
              <div class="row m-0 d-none d-md-flex" style="border-bottom: 5px #D8D8D8 solid ">
                <div class="col p-0">
                  <button type="button" value="disorder" :class="'btn-outline-primary-' + (tab == 'disorder')" @click="selectTab($event.target.value)"
                style="border-end: 1px #D8D8D8 solid">{{ he.GuidelineView.tab.item1 }}</button>    
                  <button type="button" value="treatment" :class="'btn-outline-primary-' + (tab == 'treatment')" @click="selectTab($event.target.value)">{{ he.GuidelineView.tab.item2 }}</button>              
                </div>
                <div class="col-1 p-0 ion-text-end d-none d-md-block">
                  <bookmark :guidelineId="guidelineId"/>                                  
                </div>                
              </div>              
            </div>


            <!-- Mobile view -->

            <div class="container d-block d-md-none justify-content-end">

              <!-- Guideline -->

              <div class="d-flex align-items-center">
                <ion-label class="mt-2" color="secondary" style="font-size: 20px !important; font-weight: bold;">{{ he.GuidelineView.sideBar.title }}</ion-label>
              </div>               
              <div @click="infoBox = !infoBox" class="row align-items-baseline">
                <div class="col-11">
                  <div class="d-flex align-items-center">
                    <ion-label style="font-size: 25px !important; font-weight: bold;">{{ guideline.title }}</ion-label>
                  </div>                    
                </div>
                <div class="col-1 p-2">
                  <ion-icon v-if="!infoBox" class="ion-text-end" :icon="chevronDownOutline"></ion-icon>
                  <ion-icon v-else class="ion-text-end" :icon="chevronUpOutline"></ion-icon>
                </div>
                <div v-show="infoBox" class="col infoBox">
                  <p>
                    <!-- Created by -->
                    <span class="title">{{ he.GuidelineView.sideBar.item1 }}: </span>{{ guideline.issuer }}
                  </p>
                  <p>
                    <!-- Last updated -->
                    <span class="title">{{ he.GuidelineView.sideBar.item2 }}: </span>{{ guideline.updatedAt }}
                  </p>  
                  <div v-if="guideline.definition">
                    <!-- Definition -->
                    <span class="title">{{ he.GuidelineView.sideBar.item3 }}</span><p>{{ definition }}</p>
                  </div>
                  <div v-if="findings.length">
                    <!-- Findings -->
                    <span class="title">{{ he.GuidelineView.sideBar.item4 }}</span><p>{{ findings }}</p>
                  </div>                  
                </div>
              </div>
                
              <!-- Characteristics -->

                <div v-show="characteristics.length" @click="toggleSideMenu()"
                class="row align-items-baseline p-2">
                    <div class="col-11">
                      <div class="d-flex align-items-center">
                        <ion-icon color="secondary" :icon="optionsOutline"></ion-icon>
                        <ion-label color="secondary"  class="mx-2" style="font-weight: bold;">{{ he.GuidelineView.sideBar.title2 }}</ion-label>
                      </div>                    
                    </div>
                    <div class="col-1">
                      <ion-icon class="ion-text-end" :icon="chevronBackOutline"></ion-icon>
                    </div>
                </div>                  
            </div> 


            <!-- Tab menu -->
            <div class="sticky-top d-block d-md-none mb-3">
              <ion-segment :value="tab" @ionChange="selectTab($event.target.value)"> 
                <ion-segment-button value="disorder">
                  <ion-label>{{ he.GuidelineView.tab.item1 }}</ion-label>
                </ion-segment-button>
                <ion-segment-button value="treatment"> 
                  <ion-label>{{ he.GuidelineView.tab.item2 }}</ion-label>
                </ion-segment-button>      
              </ion-segment>                
            </div>              

            <!-- Tests tab -->
            <div v-if="tab == 'disorder'" class="container me-md-0">

              <!-- Tests -->
              <div v-if="guideline.tests != undefined && guideline.tests.length > 0" class="pb-md-5">
                <ion-item
                @click="viewingTestId == test.id ? viewingTestId = -1 : viewingTestId = test.id;"
                 color="light" lines="none" :class="viewingTestId == test.id ? 'opened mb-2' : 'mb-2'" button detail="false"
                  v-for="test in guideline.tests" :key="test.id">
                  <ion-label class="ion-text-wrap px-md-4 py-1">
                    <div class="row align-items-center">
                      <div class="col-10 col-md-9 col-lg-10">
                        <ion-icon class="priorityCircle" :color="getPriorityColor(test.priorityId)" :icon="ellipse"></ion-icon>
                        <ion-label class="priority d-inline mx-1">{{ he.GuidelineView.rubric.priority }}: {{ test.priorityTitle }}</ion-label> 
                        <p class="title">{{ test.titleHe }}, {{ test.title }}</p>
                      </div>
                      <div class="col-2 col-md-3 col-lg-2">
                        <div class="ion-text-end">
                            <div class="d-none d-lg-block">
                              <button class="actionBtn" v-if="viewingTestId != test.id && hasDetails(test)">
                              {{ he.GuidelineView.rubric.button1 }} ></button>
                            <button v-else-if="viewingTestId == test.id && hasDetails(test)" class="actionBtn">
                              {{ he.GuidelineView.rubric.button2 }} </button>                                
                            </div>
                            <div class="d-lg-none">
                              <ion-icon v-if="viewingTestId != test.id && hasDetails(test)" :icon="chevronDownOutline"></ion-icon>
                              <ion-icon v-else-if="viewingTestId == test.id && hasDetails(test)" :icon="chevronUpOutline"></ion-icon>
                            </div>                                                     
                        </div>                        
                      </div>                                                                       
                    </div>      
                          
                    <div v-show="viewingTestId == test.id && hasDetails(test)">                     

                      <div class="row mt-2 mt-md-3">                       
                        
                        <div class="col-12 col-md-11" v-if="test.criteria != undefined">
                          <div class="detailsBox">
                            <h3>{{ he.GuidelineView.rubric.subtitle1 }}: </h3>
                            <span class="light-text">{{ test.criteria }}</span> 
                          </div>                           
                        </div>

                        <div class="col-12 col-md-11" v-if="test.purpose != undefined">
                          <div class="detailsBox">
                            <h3>{{ he.GuidelineView.rubric.subtitle2 }}: </h3>
                            <span class="light-text">{{ test.purpose }}</span>
                          </div>                   
                        </div>
                        <div class="col-12 col-md-11" v-if="test.instructions != undefined">
                          <div class="detailsBox">
                            <h3>{{ he.GuidelineView.rubric.subtitle3 }}: </h3>
                            <span class="light-text">{{ test.instructions }}</span>
                          </div>         
                        </div> 
                        <div class="col-12 col-md-11" v-if="test.interpretation != undefined">
                          <div class="detailsBox">
                            <h3>{{ he.GuidelineView.rubric.subtitle4 }}: </h3>
                            <span class="light-text">{{ test.interpretation }}</span>
                          </div>
                        </div>
                        <div class="col-12 col-md-11 mb-0" v-if="test.notes != undefined">
                          <div class="detailsBox notes">
                            <h3>{{ he.GuidelineView.rubric.subtitle5 }}: </h3>
                            <span class="light-text">{{ test.notes }}</span>
                          </div>  
                        </div>                  
                      </div>                                             
                    </div>                                                                                                                                                 
                  </ion-label>
                </ion-item>          
              </div>

            </div>

            <!-- Treatments tab -->
            <div v-if="tab == 'treatment'" class="container me-md-0">
              <!-- Treatments -->
              <div v-if="guideline.treatments != undefined && guideline.treatments.length > 0" class="pb-3 pb-md-5">
                
                <ion-item color="light" lines="none" class="mb-2" button detail="false"
                @click="viewingTreatmentId == treatment.id ? viewingTreatmentId = -1 : viewingTreatmentId = treatment.id;"
                v-for="treatment in guideline.treatments" :key="treatment.id">
                <ion-label class="ion-text-wrap px-md-4 py-1">

                  <div class="row align-items-center">
                    <div class="col-10 col-md-9 col-lg-10">
                      <ion-icon class="priorityCircle" :color="getPriorityColor(treatment.priorityId)" :icon="ellipse"></ion-icon> 
                      <ion-label class="d-inline mx-1">{{ he.GuidelineView.rubric.priority }}: {{ treatment.priorityTitle }}</ion-label> 
                      <p class="title">{{ treatment.titleHe }}, {{ treatment.title }}</p>
                    </div>
                    <div class="col-2 col-md-3 col-lg-2">
                      <div class="ion-text-end">
                        <div class="d-none d-md-block">
                            <button class="actionBtn" v-if="viewingTreatmentId != treatment.id && hasDetails(treatment)">{{ he.GuidelineView.rubric.button1 }} ></button>
                            <button v-else-if="viewingTreatmentId == treatment.id && hasDetails(treatment)" class="actionBtn">{{ he.GuidelineView.rubric.button2 }}</button>                                
                        </div>
                        <div class="d-block d-md-none">
                          <ion-icon v-if="viewingTreatmentId != treatment.id && hasDetails(treatment)" :icon="chevronDownOutline"></ion-icon>
                          <ion-icon v-else-if="viewingTreatmentId == treatment.id && hasDetails(treatment)" :icon="chevronUpOutline"></ion-icon>
                        </div>                                                     
                      </div>                      
                    </div>
                  </div>

                  <div v-show="viewingTreatmentId == treatment.id && hasDetails(treatment)">

                    <div class="row mt-2 mt-md-3">

                      <div class="col-12 col-md-11" v-if="treatment.criteria != undefined">
                        <div class="detailsBox">
                          <h3>{{ he.GuidelineView.rubric.subtitle1 }}: </h3>
                          <span class="light-text">{{ treatment.criteria }}</span> 
                        </div>                           
                      </div>
                
                      <div class="col-12 col-md-11" v-if="treatment.instructions != undefined">
                        <div class="detailsBox">
                          <h3>{{ he.GuidelineView.rubric.subtitle3 }}: </h3>
                          <span class="light-text">{{ treatment.instructions }}</span>
                        </div>         
                      </div> 

                      <div class="col-12 col-md-11" v-if="treatment.exclude != undefined">
                        <div class="detailsBox">
                          <h3>{{ he.GuidelineView.rubric.subtitle6 }}: </h3>
                          <span class="light-text">{{ treatment.exclude }}</span>
                        </div>         
                      </div>                      

                      <div class="col-12 col-md-11" v-if="treatment.interpretation != undefined">
                        <div class="detailsBox">
                          <h3>{{ he.GuidelineView.rubric.subtitle4 }}: </h3>
                          <span class="light-text">{{ treatment.interpretation }}</span>
                        </div>
                      </div>

                      <div class="col-12 col-md-11" v-if="treatment.notes != undefined">
                        <div class="detailsBox notes">
                          <h3>{{ he.GuidelineView.rubric.subtitle5 }}: </h3>
                          <span class="light-text">{{ treatment.notes }}</span>
                        </div>  
                      </div>                  
                    </div>                                             
                  </div>                                                                                                                                                 
                </ion-label>                  
                </ion-item>          
              </div>        
            </div>  
          </div>

          <div v-else class="container">
            <GuidelinesBox/>
          </div>
        </div>
      </div>  
    </ion-content>    
  </ion-page> 
</template>

<script>
import he from '../json/he.json';
import Header from '../components/headers/myHeader.vue';
import GuidelinesBox from '../components/GuidelinesBox.vue';
import bookmark from '../components/bookmarkGuideline.vue';
import patientCharacteristics from '../components/PatientCharacteristics.vue';
import { IonPage, IonContent, IonSegment, IonSegmentButton, IonItem, IonLabel, IonIcon, menuController } from '@ionic/vue';
import { chevronUpOutline, chevronDownOutline, chevronBackOutline, ellipsisVertical, ellipse, documentOutline, optionsOutline } from 'ionicons/icons';
import { mapState, mapGetters } from 'vuex';

export default {

  components: {
    IonPage, IonContent,IonSegment,
    IonSegmentButton, IonItem, IonLabel,
    IonIcon,
    Header, GuidelinesBox, bookmark,
    patientCharacteristics
  },
  
  setup() {
    return {
      chevronUpOutline,
      chevronDownOutline,
      chevronBackOutline,
      ellipsisVertical,
      ellipse,
      documentOutline,
      optionsOutline
    }
  },  

  data() {
    return {
      he,
      tab: "disorder",
      infoBox: false,
      sideBar: {
        opened: true,
        characteristics: false
      },
      readMore: {
        definition: false,
        findings: false,
      },
      viewingTestId: -1,
      viewingTreatmentId: -1
    }
  },

  computed: {

    ...mapState(["guideline", "query"]),
    ...mapGetters(["characteristics"]),

    guidelineId() {
      return this.guideline.id != undefined ? this.guideline.id : Number(this.$route.params.id);
    },

    definition() {
      return this.readMore.definition || this.guideline.definition.length <= 214 ? this.guideline.definition : this.guideline.definition.substring(0, 202) + " ";
    },

    findings() {
      return this.readMore.findings || this.getFindings().join().length && this.getFindings().join().length <= 214  ? this.getFindings().join() + "." : this.getFindings().join().substring(0, 202);
    }

  },

  methods: {

    selectTab(input) {
      this.tab = input;
    },

    readMoreBtn(value) {
      return value.length > 250;
    },    

    getFindings() {

      const findings = [];
      if (this.guideline.findings != undefined && this.guideline.findings.length > 0) {

        for (let i=0; i<this.guideline.findings.length; i++) {
          findings.push(this.guideline.findings[i].title);
        }

      }

      return findings;
    },

    getPriorityColor(level) {

      switch(level) {
        case 5:
        case 4:
          return 'danger'
        case 3:
        case 2:
          return 'tertiary'
        default:
          return 'medium'
      }
    },
    
    toggleSideMenu() {

      this.sideBar.opened = !this.sideBar.opened;
      if (window.matchMedia('(max-width: 768px)').matches)
        menuController.toggle();
    },
    
    hasDetails(object) {
      return !!object.criteria || !!object.purpose || !!object.instructions ||
      !!object.interpretation || !!object.notes
    }    

  }
};
</script>

<style scoped>

.btn-outline-primary-true,
.btn-outline-primary-false {
  line-height: 35px;
  background: none;
  font-weight: 600;
  width: 167px;
  border-radius: 0;
  border-bottom: 5px solid;
  margin-bottom: -5px;
}

.btn-outline-primary-true:focus,
.btn-outline-primary-false:focus {
  outline: 0 !important;
}

.btn-outline-primary-false:hover{
  color: var(--ion-color-secondary);
  border-bottom-color: var(--ion-color-secondary);
}

.btn-outline-primary-true {
  color: var(--ion-color-secondary);
  border-bottom-color: var(--ion-color-secondary);
}

.btn-outline-primary-false {
  border-bottom-color: #D8D8D8;
}

.priorityCircle {
  width: 9px;
  height: 9px;
}

.notes {
  background-color: #FFF5C2 !important;
}

div.item .title,
div.last-item .title {
  font-weight: 600;
}
.infoBox {
  padding: 0;
  background-color: var(--ion-color-light);
}

.infoBox p,
.infoBox div {
  margin: 0;
  padding: 10px;
  border-bottom: 5px solid var(--ion-background-color);
  width: 100%;
}

.infoBox div p {
  padding: 0;
  border: 0;
}


.opened {
  box-shadow: 0px 0px 6px #00000029;
}

p.title {
  font-size: 20px !important;
  font-weight: 600;
}

h1 {
  font-size: 25px !important;
}

.light-text {
  color: #474747;
  font-weight: 400;
}

@media only screen and (max-width: 768px) {
  .info {
    font-size: 17px !important;
  }

  .criteria {
    color: #474747
  }

  ion-segment-button {
      font-size: 18px !important;
      font-weight: 600;
      background: var(--ion-color-light);
      --indicator-color: var(--ion-color-secondary) !important;
      --indicator-height: 5px;
      border: 1px solid #C9C9C9;
      border-radius: 0;
      min-height: 50px;
  }

  .opened {
    box-shadow: 0px 0px 10px #00000029;	
  }

  span.title {
    font-weight: bold !important;
  }

  p.title {
  font-size: 18px !important;
  font-weight: 600;
  }  
  .priority {
  font-size: 18px !important; 
  }  

}

</style>
