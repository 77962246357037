<template>
    <ion-header>

        <!-- Desktop view -->
        <div class="container-fluid ps-lg-4 py-3 d-none d-md-block" style="background-color: var(--ion-color-primary);">
            <div class="row px-lg-5">
                <div class="col-3 align-self-center">
                    <user-panel/>
                </div>
                <div class="col-6">
                    <search-bar/>                    
                </div>
                <div class="col-3 ion-text-end align-self-end">
                    <my-logo/>             
                </div>
            </div>
        </div> 

        <!-- Mobile view -->
        <ion-toolbar class="d-block d-md-none">
            <ion-buttons>
                <ion-back-button default-href="/home"></ion-back-button>
                <div class="w-100 ion-text-end" style="margin-left: 12px;">
                    <bookmark :guidelineId="guidelineId"/>  
                </div>                        
            </ion-buttons>
        </ion-toolbar> 
    </ion-header>    
</template>

<script>

import myLogo from '../myLogo.vue';
import userPanel from '../userPanel.vue';
import searchBar from '../searchBar.vue';
import bookmark from '../bookmarkGuideline.vue';
import { IonHeader, IonToolbar, IonButtons, IonBackButton } from '@ionic/vue';
import { mapState } from 'vuex';

export default {
    components: {
        IonHeader, IonToolbar,
        IonButtons, IonBackButton,
        myLogo, userPanel, searchBar,
        bookmark
    },
    
    computed: {
        ...mapState(["guideline"]),

        guidelineId() {
        return this.guideline.id != undefined ? this.guideline.id : Number(this.$route.params.id);
        },        
    }
}
</script>